import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-secondary-link',
    imports: [RouterLink, NgClass],
    templateUrl: './secondary-link.component.html',
    styleUrl: './secondary-link.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecondaryLinkComponent {
  link = input.required<string | string[]>();
  linkQueryParams = input<Record<string, string>>();
  linkTitle = input.required<string>();
  color = input<'light' | 'dark'>('light');
}
