import { ChangeDetectionStrategy, Component } from '@angular/core';
import {ContainerComponent} from "../container/container.component";

@Component({
    selector: 'app-section-item',
    imports: [
        ContainerComponent
    ],
    templateUrl: './section-item.component.html',
    styleUrl: './section-item.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionItemComponent {

}
