import { computed, inject, Injectable, signal, Signal } from '@angular/core';
import { StoreApiService } from '../store-api.service';
import { CustomerStateService } from './customer-state.service';
import { productAssociations } from '../productAssociations';
import { Schemas } from '../../api-types/storeApiTypes';

export interface WishlistState {
  state: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class WishlistStateService {
  private readonly store = inject(StoreApiService);
  private readonly customerState = inject(CustomerStateService);

  private readonly state = signal<{
    products: Record<string, Schemas['Product']>;
  }>({
    products: {},
  });

  readonly detailState = computed(() => {
    const state = this.state();
    return Object.values(state.products);
  });

  constructor() {
    this.customerState.isLoaded.then((customerState) => {
      if (customerState.loggedIn) {
        return this.updateWishlist();
      }
      return Promise.resolve();
    });
  }

  private async updateWishlist() {
    const result = await this.store.apiClient.invoke(
      'readCustomerWishlist post /customer/wishlist',
      {
        body: {
          associations: productAssociations,
        },
      },
    );
    this.state.set({
      products: result.data.products.elements.reduce(
        (acc: Record<string, Schemas['Product']>, product) => {
          acc[product.id] = product;
          return acc;
        },
        {},
      ),
    });
  }

  isOnWishlist(productId: string): Signal<WishlistState> {
    return computed(() => {
      const state = this.state();
      return state.products[productId] ? { state: true } : { state: false };
    });
  }

  async addToWishlist(product: Schemas['Product']) {
    const signedUp = await this.customerState.ensureSignedUp();
    if (!signedUp) {
      return;
    }
    await this.store.apiClient.invoke(
      'addProductOnWishlist post /customer/wishlist/add/{productId}',
      {
        pathParams: { productId: product.id },
      },
    );
    this.state.update((state) => {
      return {
        products: {
          ...state.products,
          [product.id]: product,
        },
      };
    });
  }

  async removeFromWishlist(productId: string) {
    const signedUp = await this.customerState.ensureSignedUp();
    if (!signedUp) {
      return;
    }

    await this.store.apiClient.invoke(
      'deleteProductOnWishlist delete /customer/wishlist/delete/{productId}',
      {
        pathParams: { productId },
      },
    );
    this.state.update((state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [productId]: old, ...newObject } = state.products;
      return {
        products: newObject,
      };
    });
  }
}
