<form
  [formGroup]="form"
  class="flex flex-col space-y-4 py-6 divide-y divide-gray-900/10 @container"
>
  <app-form-section
    [header]="'Neues Konto erstellen'"
    [description]="
      'Erstelle in kürze ein Konto und profitiere von zusätzlichen Funktionen auf unserer Webseite'
    "
  >
    <div class="@container/form">
      <div class="grid grid-cols-1 gap-4 @lg/form:grid-cols-2">
        <app-select
          [label]="'Anrede'"
          class="@lg/form:col-span-2"
          [control]="form.controls.salutationId"
        >
          @if (salutations(); as salutationState) {
            @for (salutation of salutationState.elements; track salutation.id) {
              <option
                [value]="salutation.id"
                [label]="salutation.displayName"
              ></option>
            }
          }
        </app-select>

        <app-input
          [label]="'Vorname'"
          [control]="form.controls.firstName"
          autocomplete="given-name"
        ></app-input>
        <app-input
          [label]="'Nachname'"
          [control]="form.controls.lastName"
          autocomplete="family-name"
        ></app-input>

        <app-input
          [label]="'Straße und Hausnummer'"
          class="@lg/form:col-span-2"
          [control]="form.controls.street"
          [autocomplete]="'shipping address-line1'"
        ></app-input>
        <app-input
          [label]="'PLZ'"
          [control]="form.controls.zipcode"
          [autocomplete]="'shipping postal-code'"
        ></app-input>
        <app-input
          [label]="'Ort'"
          [control]="form.controls.city"
          [autocomplete]="'shipping address-level2'"
        ></app-input>

        <app-select
          [label]="'Land'"
          [control]="form.controls.countryId"
          class="@lg/form:col-span-2"
        >
          @if (countries(); as countryState) {
            @if (!countryState.loading) {
              @for (country of countryState.elements; track country.id) {
                <option [value]="country.id" [label]="country.name"></option>
              }
            }
          }
        </app-select>

        <app-input
          [label]="'E-Mail'"
          [type]="'email'"
          [control]="form.controls.email"
          autocomplete="email"
          class="@lg/form:col-span-2"
        ></app-input>

        <app-input
          [label]="'Passwort'"
          [control]="form.controls.password"
          [type]="'password'"
          class="@lg/form:col-span-2"
        ></app-input>

        <app-checkbox
          [control]="form.controls.dataProtection"
          class="@lg/form:col-span-2"
        >
          <span
            >Ich habe die
            <a
              class="underline decoration-primary"
              target="_blank"
              [appEvent]="'open-privacy-policy'"
              href="/Informationen/Datenschutz"
              >Datenschutzbestimmungen</a
            >
            zur Kentniss genommen und die
            <a
              target="_blank"
              class="underline decoration-primary"
              [appEvent]="'open-terms-and-conditions'"
              href="/Informationen/AGB"
              >AGB</a
            >
            gelesen und bin mit ihnen einverstanden.</span
          >
        </app-checkbox>
      </div>
    </div>
    <div
      class="flex flex-col @md:grid @md:grid-cols-2 @md:gap-x-6 @md:space-y-0 space-y-6 py-6"
    >
      @if (errorMessage(); as error) {
        <div
          class="px-3 py-2 border rounded-md border-primary-orange text-primary-orange bg-primary-orange/10"
        >
          {{ error }}
        </div>
      }
      <app-button-primary [action]="register">
        Registrieren
      </app-button-primary>
      <div class="flex items-center md:hidden">
        <div class="border-b border-gray-300 flex-grow min-w-0"></div>
        <p class="text-gray-500 text-center px-3">bereits ein Konto?</p>
        <div class="border-b border-gray-300 flex-grow min-w-0"></div>
      </div>
      <app-secondary-link
        [link]="'/account/login'"
        [linkQueryParams]="{ email: form.controls.email.value }"
        class="w-full"
        [linkTitle]="'Zur Anmeldung'"
      >
        Zur Anmeldung
      </app-secondary-link>
    </div>
  </app-form-section>
</form>
