import { components } from '../api-types/storeApiTypes';

export const productAssociations = {
  properties: {
    associations: {
      group: {},
    },
  },
  categories: {
    associations: {
      seoUrls: {},
    },
  },
  productReviews: {},
  media: {},
  seoUrls: {},
};

export const productAggregations: components['schemas']['Aggregations'] = [
  {
    name: 'filters',
    type: 'terms',
    field: 'properties.group.name',
    aggregation: {
      name: 'options',
      type: 'entity',
      definition: 'property_group_option',
      field: 'properties.id',
    },
  },
  {
    name: 'properties',
    type: 'terms',
    field: 'properties.group.id',
    aggregation: {
      name: 'options',
      type: 'terms',
      field: 'properties.id',
    },
  },
  {
    name: 'price-range',
    type: 'stats',
    field: 'price',
  },
] as const;
